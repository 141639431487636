import React from 'react';
import { useRouter } from 'next/router';

import { FullscreenAd } from 'googlead/components/FullscreenAd';
import { useNoHeader } from 'util/useNoHeader';
import { NewsLetterPopup } from 'googlead/components/NewsLetterPopup';
import { SizeMonitor } from 'components/size/SizeMonitor';

export const TopAd = ({ id } : { id: string }) => {
  const router = useRouter();
  const elKey = router.asPath;
  const { noHeader } = useNoHeader();
  if (noHeader) {
    return null;
  }

  return (
    <>
      <SizeMonitor waitInteraction><FullscreenAd key={`${id}_${elKey}`} /></SizeMonitor>
      <NewsLetterPopup key={`${id}_${elKey}_2`} />
    </>
  );
};
